<template>
    <div class="login-view">
        <v-card class="card-padding login-box">
            <div class="card-form">
                <div class="header-form">
                        <div class="text-header">Login</div>
                </div>
                <v-form ref="form">
                    <v-text-field
                        v-model="form.username"
                        :rules="inputRules"
                        label="Username"
                        :error-messages="errors.username"
                        required
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="form.password"
                        :rules="inputRules"
                        label="Password"
                        :error-messages="errors.password"
                        type="password"
                        required
                    >
                    </v-text-field>
                    <div class="btn-container">
                        <v-btn color="primary" @click="submit">
                            Login
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'Login',

    data () {
        return {
            form: {
                username: '',
                password: ''
            },
            inputRules: [
                v => !!v || 'This field is required'
            ],
            errors: {},
            isSuperAdmin: false
        }
    },

    methods: {
        async submit () {
            if (!this.$refs.form.validate()) {
                return
            }
            const res = await this.axios.post('/login', this.form)
            if (res && res.data && res.data.status === 'ok') {
                localStorage.setItem('jwt', res.data.data.token)
                const isSuperAdmin = this.parseJwt(res.data.data.token)
                localStorage.setItem('isSuperAdmin', isSuperAdmin)
                if (isSuperAdmin) {
                    localStorage.setItem('branches', '')
                    this.$router.push({ path: '/branches' })
                } else {
                    localStorage.setItem('branches', res.data.data.branchIds)
                    this.$router.push({ path: '/students' })
                }
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        parseJwt (token) {
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            }).join(''))
            return JSON.parse(jsonPayload).isSuperAdmin
        }
    }
}
</script>

<style lang="scss" scoped>
.login-view {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #502B94;
    opacity: 0.7;
    .login-box {
        width: 500px;
    }
}
</style>
